import Head from "next/head";
import Image from "next/image";
import { GetServerSidePropsContext } from "next";

import { Text } from "ui/dist/components/Text";
import { FormFooter } from "ui/dist/components/Form";
import { ButtonGroup } from "ui/dist/components/Button";
import { serverSidePropsHandler } from "../utils/serverSidePropsHandler";
import logoLight from "../public/icons/cryptoken-ticker-logo-96x53-light.png";
import logoDark from "../public/icons/cryptoken-ticker-logo-96x53-dark.png";
import { Box } from "ui/dist/components/Box";
import { styled } from "ui/dist/stitches.config";
import { Heading } from "ui/dist/components/Heading";
import { useDarkMode } from "ui/dist/hooks/darkMode";
import { AppLayout } from "../components/AppLayout";
import { AppLink } from "../components/AppLink";

const LogoContaner = styled(Box, {
    alignItems: "center",
    justifyContent: "center",
    h1: { ml: "$2" },
});

function IndexPage() {
    const [darkMode] = useDarkMode();

    return (
        <>
            <Head>
                <title>Crypto Coin & Token Ticker</title>
            </Head>
            <LogoContaner>
                <Image
                    src={darkMode ? logoDark : logoLight}
                    alt="Crypto Coin & Token Ticker"
                    layout="fixed"
                />
                <Heading as="h1" css={{ fontSize: "$4", m: 0 }}>
                    Cryptoken Ticker
                </Heading>
            </LogoContaner>
            <Text>Crypto currency coin & token tickers on any device.</Text>
            <ul>
                <li>Track any symbol on Kucoin & Coinbase</li>
                <li>Multuiple tickers</li>
                <li>Prices update every 1-5 minutes</li>
                <li>
                    Turn your Raspberry Pi, or any device, into a crypto coin
                    tracker
                </li>
                <li>Easily connect and manage remote token ticker devices</li>
                <li>Dark mode</li>
                <li>Highlights big movers</li>
            </ul>
            <Text variant="info">
                <strong>Beta:</strong> this is a work in progress, if you have
                any problems or find any bugs please get in touch{" "}
                <a href="mailto:contact@cryptokenticker.com">
                    contact@cryptokenticker.com
                </a>
            </Text>
            <FormFooter>
                <ButtonGroup>
                    <AppLink href="/signup" button>
                        Sign up
                    </AppLink>
                    <AppLink href="/login" button>
                        Log in
                    </AppLink>
                </ButtonGroup>
            </FormFooter>
        </>
    );
}

// eslint-disable-next-line react/display-name
IndexPage.getLayout = (page: React.ReactNode) => (
    <AppLayout contained hideHeader>
        {page}
    </AppLayout>
);

export default IndexPage;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
    return serverSidePropsHandler({ ctx, redirectAuthenticated: true });
}
