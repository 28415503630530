var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { styled } from "../stitches.config";
const StyledForm = styled("form", {
    display: "flex",
    flexDirection: "column",
});
export const FormActions = styled("div", {
    display: "flex",
    justifyContent: "flex-end",
    mt: "$5",
});
export const FormFooter = styled("div", {
    display: "flex",
    justifyContent: "center",
    mt: "$4",
});
const serializeForm = function (form, defaultValues) {
    const obj = defaultValues;
    const formData = new FormData(form);
    const formElements = form.elements;
    for (let key of formData.keys()) {
        let _key = key;
        let _value = formData.get(_key);
        if (["checkbox", "radio"].includes(formElements[_key].type)) {
            _value = _value = "on" ? true : false;
        }
        // if an array
        // @TODO parse array checkbox/radio items
        if (_key.endsWith("[]")) {
            obj[_key.substring(0, _key.length - 2)] =
                formData.getAll(_key);
            continue;
        }
        obj[_key] = _value;
    }
    return obj;
};
export const Form = (_a) => {
    var { children, onSubmit, validationSchema, defaultValues } = _a, rest = __rest(_a, ["children", "onSubmit", "validationSchema", "defaultValues"]);
    const [errorsState, setErrorsState] = useState(() => ({}));
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const handleOnSubmit = (ev) => __awaiter(void 0, void 0, void 0, function* () {
        ev.preventDefault();
        const target = ev.currentTarget;
        const values = serializeForm(target, defaultValues);
        setErrorsState({});
        let result = {
            values,
            isValid: false,
            errors: {},
        };
        yield validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
            result.isValid = true;
        })
            .catch((err) => {
            result.errors = err.inner.reduce((acc, cur) => {
                return Object.assign(Object.assign({}, acc), { [`${cur.path}`]: cur.message });
            }, {});
            setErrorsState(() => result.errors);
        })
            .finally(() => {
            setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                setIsSubmitting(() => true);
                yield onSubmit(result, ev);
                setIsSubmitting(() => false);
            }), 0);
        });
    });
    return (_jsx(StyledForm, Object.assign({ onSubmit: handleOnSubmit }, rest, { children: children({
            errors: errorsState,
            isValid: Object.keys(errorsState).length < 1,
            isSubmitting,
        }) })));
};
