import { styled } from "../stitches.config";
const COLUMNS = 12;
const COLUMNS_WIDTH = 100 / COLUMNS;
export const Box = styled("div", {
    display: "flex",
    width: "100%",
    variants: {
        direction: {
            column: {
                flexDirection: "column",
            },
        },
        justifyContent: {
            between: { justifyContent: "space-between" },
        },
        cols: Object.fromEntries(Array(12)
            .fill(0)
            .map((a, i) => [i + 1, { width: `${COLUMNS_WIDTH * i + 1}%` }])),
    },
});
